import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Finished logic chip`}</h4>
    <p>{`The production of a single logic chip often requires more than 1,000 steps, passing through international borders 70 or more times, before reaching an end customer. The result of this complex, $500 billion supply chain is a resource fundamental to virtually every aspect of modern life.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      